import React, {useState, useEffect} from 'react';
// import {useTranslation} from 'react-i18next';
import Dashboard from 'components/layouts/dashboard';
import Login from 'components/login';
import {ConfigProvider, Spin} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {checkSessionToken, describePermissions} from 'actions';
import ru_RU from 'antd/es/locale/ru_RU';
import en_US from 'antd/es/locale/en_US';

const locales = {en: en_US, ru: ru_RU};
const Main = () => {
    // const [t] = useTranslation();
    const locale = useSelector(state => state.app.lang);
    const [loading, setLoading] = useState(true);
    const logged = useSelector(state => state.auth.logged);
    const dispatch = useDispatch();
    // /* eslint-disable no-template-curly-in-string */
    // const typeTemplate = t('Field is not a valid ${type}');
    // const validateMessages = useMemo(() => ({
    //     default: t('Validation error'),
    //     required: t('Field is required'),
    //     enum: t('Field must be one of [${enum}]'),
    //     whitespace: t('Field cannot be empty'),
    //     date: {
    //         format: t('Field is invalid for format date'),
    //         parse: t('Field could not be parsed as date'),
    //         invalid: t('Invalid date'),
    //     },
    //     types: {
    //         string: typeTemplate,
    //         method: typeTemplate,
    //         array: typeTemplate,
    //         object: typeTemplate,
    //         number: typeTemplate,
    //         date: typeTemplate,
    //         boolean: typeTemplate,
    //         integer: typeTemplate,
    //         float: typeTemplate,
    //         regexp: typeTemplate,
    //         email: typeTemplate,
    //         url: typeTemplate,
    //         hex: typeTemplate,
    //     },
    //     string: {
    //         len: t('Field must be exactly ${len} characters'),
    //         min: t('Field must be at least ${min} characters'),
    //         max: t('Field cannot be longer than ${max} characters'),
    //         range: t('Field must be between ${min} and ${max} characters'),
    //     },
    //     number: {
    //         len: t('Field must equal ${len}'),
    //         min: t('Field cannot be less than ${min}'),
    //         max: t('Field cannot be greater than ${max}'),
    //         range: t('Field must be between ${min} and ${max}'),
    //     },
    //     array: {
    //         len: t('Field must be exactly ${len} in length'),
    //         min: t('Field cannot be less than ${min} in length'),
    //         max: t('Field cannot be greater than ${max} in length'),
    //         range: t('Field must be between ${min} and ${max} in length'),
    //     },
    //     pattern: {
    //         mismatch: t('Field does not match pattern ${pattern}'),
    //     },
    // }), [t, typeTemplate]);

    useEffect(() => {
        dispatch(checkSessionToken()).then(() => setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(describePermissions()).then(() => setLoading(false));
    },[logged, dispatch]);

    if (loading) {
        return <Spin/>
    }

    return (
        <ConfigProvider locale={locales[locale]}>
            {logged ? <Dashboard/> : <Login/>}
        </ConfigProvider>
    );
};

export default Main;
