import store from '../store';

export function dateFormatter(date, options = {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
    timeZone: store.getState() ? store.getState().auth.data.timezone : 'UTC'
}) {
    if (date) {
        const locale = store.getState().app.lang;
        const new_date = new Intl.DateTimeFormat(locale, options);
        return new_date.format(new Date(date));
    }
    return null;
}