import React, {lazy, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Layout, Menu, Spin} from 'antd';
import SidebarMenu from './sidebarMenu';
import User from './user';
import Lang from './lang';
import NotFound from 'components/not-found';
import './style.css';
import withAccessActions from 'components/page-wrapper';
import {
    SYS_VIEW_ACCESS_PROFILES,
    SYS_VIEW_PERSONS,
    SYS_VIEW_ENTITIES,
    SYS_VIEW_CONTROLLERS,
    SYS_VIEW_CARS,
    SYS_VIEW_LOGS
} from 'constants/actions';

const Main = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/main')), 300);
    });
});
const Entities = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/entities')), 300);
    });
});
const Persons = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/persons')), 300);
    });
});
const Cars = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/cars')), 300);
    });
});
const Controlled = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/controlled')), 300);
    })
});
const Logs = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/logs')), 300);
    })
});
const AccessProfiles = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('components/access-profiles')), 300);
    })
});

const Dashboard = (props) => (
    <Layout className="main-layout">
        <Layout.Sider
            theme="dark"
            breakpoint="lg"
            collapsedWidth="0"
        >
            <div className="logo"/>
            <SidebarMenu/>
        </Layout.Sider>
        <Layout className="site-layout">
            <Layout.Header className="site-layout-background">
                <Menu mode="horizontal">
                    <Menu.Item key="mail">
                        {/*Navigation One*/}
                    </Menu.Item>
                    <span className="mr-auto" style={{marginRight: 'auto'}}/>
                    <Lang />
                    <User/>
                </Menu>
            </Layout.Header>
            <Layout.Content className="site-layout-content">
                <div className="main-content">
                    <Suspense fallback={<Spin/>}>
                        <Switch>
                            <Route exact path="/" component={Main}/>
                            {props.checkAccessRight(SYS_VIEW_ENTITIES) &&
                                <Route path="/entities" component={Entities}/>
                            }
                            {props.checkAccessRight(SYS_VIEW_PERSONS) &&
                                <Route path="/persons" component={Persons}/>
                            }
                            {props.checkAccessRight(SYS_VIEW_CARS) &&
                                <Route path="/cars" component={Cars}/>
                            }
                            {props.checkAccessRight(SYS_VIEW_CONTROLLERS) &&
                                <Route path="/controlled" component={Controlled}/>
                            }
                            {props.checkAccessRight(SYS_VIEW_LOGS) &&
                                <Route path="/logs" component={Logs}/>
                            }
                            {props.checkAccessRight(SYS_VIEW_ACCESS_PROFILES) &&
                                <Route path="/access-profiles" component={AccessProfiles}/>
                            }
                            <Route path={['*', '404']} component={NotFound}/>
                        </Switch>
                    </Suspense>
                </div>
            </Layout.Content>
        </Layout>
    </Layout>
);

export default withAccessActions(
    Dashboard,
    [SYS_VIEW_ACCESS_PROFILES, SYS_VIEW_PERSONS, SYS_VIEW_ENTITIES,
            SYS_VIEW_CONTROLLERS, SYS_VIEW_CARS, SYS_VIEW_LOGS]
);
