import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Menu} from 'antd';
import {LANG, SWITCH_LANG} from 'constants/index';

const Lang = props => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const handleChange = _lang => {
        if (i18n.language !== _lang) {
            localStorage.setItem(LANG, _lang);
            i18n.changeLanguage(_lang).then(() => {
                dispatch({type: SWITCH_LANG, lang: _lang});
            });
        }
    };

    return (
        <Menu.SubMenu key="lang" {...props} title={t('Select language')}>
            <Menu.Item key="setting:1" onClick={() => handleChange('ru')}>
                Русский
            </Menu.Item>
            <Menu.Item key="setting:2" onClick={() => handleChange('en')}>
                English
            </Menu.Item>
        </Menu.SubMenu>
    )
};

export default memo(Lang);
