import {SWITCH_LANG, LANG} from 'constants/index';

const initialState = {
    lang: localStorage.getItem(LANG) || 'ru',
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        default:
            return state;
    }
};

export default app;