import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Menu, Modal} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useDispatch} from 'react-redux';
import {logout} from 'actions/auth';

const User = props => {
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const handleOk = () => {
        Modal.confirm({
            title: t('Logout confirm'),
            content: t('Are you sure you want to logout?'),
            okText: t('Yes'),
            cancelText: t('Cancel'),
            onOk() {
                dispatch(logout())
            }
        })
    };

    return (
        <Menu.SubMenu {...props} icon={<Avatar icon={<UserOutlined/>}/>}>
            <Menu.Item key="setting:1">
                {t('Profile')}
            </Menu.Item>
            <Menu.Item key="setting:2">
                {t('Settings')}
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="setting:3" danger onClick={() => handleOk()}>
                {t('Logout')}
            </Menu.Item>
        </Menu.SubMenu>
    );
};

export default memo(User);
