import i18n from 'i18next';

export function errorHandler(err, form) {
    const {data} = err.response || {};
    const t = i18n.t.bind(i18n);
    form.current.setFields(Object.keys(data).map(item => {
        const error = Number(data[item]);
        let errorNote = '';
        if (error) {
            switch (error) {
                case 422:
                    errorNote = t('Validation error');
                    break;
                case 409:
                    errorNote = t(`Record exists`);
                    break;
                case 404:
                    errorNote = t('Record not Found');
                    break;
            }
        }
        return {
            name: item,
            errors: [errorNote]
        }
    }))
}
