import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    CHECK_SESSION_TOKEN_SUCCESS,
    CHECK_SESSION_TOKEN_FAILURE,
    CHECK_SESSION_TOKEN_REQUEST,
    LOGOUT
} from 'constants/index';

const initialState = {
    logged: false,
    inProcess: false,
    failed: false,
    data: {},
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                inProcess: true,
                failed: false,
            };
        case CHECK_SESSION_TOKEN_REQUEST:
            return {
                ...state,
                inProcess: true,
            };
        case AUTH_SUCCESS:
        case CHECK_SESSION_TOKEN_SUCCESS:
            return {
                ...state,
                inProcess: false,
                logged: true,
                data: action.payload,
            };
        case AUTH_FAILURE:
            return {
                ...state,
                inProcess: false,
                failed: true,
            };
        case CHECK_SESSION_TOKEN_FAILURE:
            return {
                ...state,
                inProcess: false,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;
