export const SYS_GET_PERSONS = 'SYS_GET_PERSONS';
export const SYS_GET_PERSON = 'SYS_GET_PERSON';
export const SYS_CREATE_PERSON = 'SYS_CREATE_PERSON';
export const SYS_UPDATE_PERSON = 'SYS_UPDATE_PERSON';
export const SYS_DELETE_PERSON = 'SYS_DELETE_PERSON';

export const SYS_GET_CARS = 'SYS_GET_CARS';
export const SYS_GET_CAR = 'SYS_GET_CAR';
export const SYS_CREATE_CAR = 'SYS_CREATE_CAR';
export const SYS_UPDATE_CAR = 'SYS_UPDATE_CAR';
export const SYS_DELETE_CAR = 'SYS_DELETE_CAR';

export const SYS_GET_CONTROLLED_LIST = 'SYS_GET_CONTROLLED_LIST';
export const SYS_GET_CONTROLLED_INFO = 'SYS_GET_CONTROLLED_INFO';
export const SYS_CREATE_CONTROLLED = 'SYS_CREATE_CONTROLLED';
export const SYS_UPDATE_CONTROLLED = 'SYS_UPDATE_CONTROLLED';
export const SYS_DELETE_CONTROLLED = 'SYS_DELETE_CONTROLLED';

export const SYS_GET_CONTROLLERS = 'SYS_GET_CONTROLLERS';
export const SYS_GET_CONTROLLER = 'SYS_GET_CONTROLLER';
export const SYS_CREATE_CONTROLLER = 'SYS_CREATE_CONTROLLER';
export const SYS_UPDATE_CONTROLLER = 'SYS_UPDATE_CONTROLLER';
export const SYS_DELETE_CONTROLLER = 'SYS_DELETE_CONTROLLER';

export const SYS_GET_EMAILS = 'SYS_GET_EMAILS';
export const SYS_GET_EMAIL = 'SYS_GET_EMAIL';
export const SYS_CREATE_EMAIL = 'SYS_CREATE_EMAIL';
export const SYS_UPDATE_EMAIL = 'SYS_UPDATE_EMAIL';
export const SYS_DELETE_EMAIL = 'SYS_DELETE_EMAIL';

export const SYS_GET_ENTITIES = 'SYS_GET_ENTITIES';
export const SYS_GET_ENTITY = 'SYS_GET_ENTITY';
export const SYS_CREATE_ENTITY = 'SYS_CREATE_ENTITY';
export const SYS_UPDATE_ENTITY = 'SYS_UPDATE_ENTITY';
export const SYS_DELETE_ENTITY = 'SYS_DELETE_ENTITY';

export const SYS_GET_ENTITY_NOTES = 'SYS_GET_ENTITY_NOTES';
export const SYS_CREATE_ENTITY_NOTE = 'SYS_CREATE_ENTITY_NOTE';

export const SYS_GET_ACCESS_PROFILES = 'SYS_GET_ACCESS_PROFILES';
export const SYS_GET_ACCESS_PROFILE = 'SYS_GET_ACCESS_PROFILE';
export const SYS_CREATE_ACCESS_PROFILE = 'SYS_CREATE_ACCESS_PROFILE';
export const SYS_UPDATE_ACCESS_PROFILE = 'SYS_UPDATE_ACCESS_PROFILE';
export const SYS_DELETE_ACCESS_PROFILE = 'SYS_DELETE_ACCESS_PROFILE';

export const SYS_GET_KEYS = 'SYS_GET_KEYS';
export const SYS_GET_KEY = 'SYS_GET_KEY';
export const SYS_CREATE_KEY = 'SYS_CREATE_KEY';
export const SYS_UPDATE_KEY = 'SYS_UPDATE_KEY';
export const SYS_DELETE_KEY = 'SYS_DELETE_KEY';

export const SYS_GET_PHONE_NUMBERS = 'SYS_GET_PHONE_NUMBERS';
export const SYS_GET_PHONE_NUMBER = 'SYS_GET_PHONE_NUMBER';
export const SYS_CREATE_PHONE_NUMBER = 'SYS_CREATE_PHONE_NUMBER';
export const SYS_UPDATE_PHONE_NUMBER = 'SYS_UPDATE_PHONE_NUMBER';
export const SYS_DELETE_PHONE_NUMBER = 'SYS_DELETE_PHONE_NUMBER';

export const SYS_GET_SQUARES = 'SYS_GET_SQUARES';
export const SYS_CREATE_SQUARE = 'SYS_CREATE_SQUARE';
export const SYS_UPDATE_SQUARE = 'SYS_UPDATE_SQUARE';
export const SYS_DELETE_SQUARE = 'SYS_DELETE_SQUARE';
export const SYS_GET_ENTITY_LOGS = 'SYS_GET_ENTITY_LOGS';
export const SYS_GET_ACTION_LOGS = 'SYS_GET_ACTION_LOGS';
export const SYS_GET_GLOBAL_LOGS = 'SYS_GET_GLOBAL_LOGS';

export const SYS_VIEW_PERSONS = 'SYS_VIEW_PERSONS';
export const SYS_VIEW_CARS = 'SYS_VIEW_CARS';
export const SYS_VIEW_CONTROLLERS = 'SYS_VIEW_CONTROLLERS';
export const SYS_VIEW_ENTITIES = 'SYS_VIEW_ENTITIES';
export const SYS_VIEW_ACCESS_PROFILES = 'SYS_VIEW_ACCESS_PROFILES';
export const SYS_VIEW_LOGS = 'SYS_VIEW_LOGS';
