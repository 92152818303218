import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import {LANG} from 'constants/index';

const availableLanguages = ['en', 'ru'];

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ru',
        debug: false,
        whitelist: availableLanguages,
        lng: localStorage.getItem(LANG),
        load: 'languageOnly',
        changeLanguage: 'en',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        ns: ['translation'],
        defaultNS: 'translation'
    });

export default i18n;
