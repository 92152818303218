import {fetch} from 'helpers';
import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    CHECK_SESSION_TOKEN_FAILURE,
    CHECK_SESSION_TOKEN_REQUEST,
    CHECK_SESSION_TOKEN_SUCCESS,
    LOGOUT,
    USER_SESSION,
} from 'constants/index';

export const authorize = (params) => async dispatch => {
    dispatch({type: AUTH_REQUEST});

    try {
        const {session_token} = await fetch.post('authorize_user', params);

        if (session_token) {
            localStorage.setItem(USER_SESSION, JSON.stringify(session_token));
            dispatch({
                type: AUTH_SUCCESS,
                payload: session_token
            });
        } else {
            dispatch({type: AUTH_FAILURE});
        }
    } catch (e) {
        dispatch({type: AUTH_FAILURE});
    }
};

export const checkSessionToken = () => async dispatch => {
    dispatch({type: CHECK_SESSION_TOKEN_REQUEST});

    try {
        const user_session = JSON.parse(localStorage.getItem(USER_SESSION));
        if (user_session && typeof user_session === 'string') {
            const result = await fetch.post('check_token_is_valid', {token: user_session});
            if (result === true) {
                dispatch({
                    type: CHECK_SESSION_TOKEN_SUCCESS,
                    payload: user_session,
                });
            } else {
                dispatch({type: CHECK_SESSION_TOKEN_FAILURE});
                localStorage.removeItem(USER_SESSION);
            }

        } else {
            dispatch({type: CHECK_SESSION_TOKEN_FAILURE});
            localStorage.removeItem(USER_SESSION);
        }
    } catch (e) {
        console.error(e);
        dispatch({type: CHECK_SESSION_TOKEN_FAILURE});
        localStorage.removeItem(USER_SESSION);
    }
};


export const logout = () => async dispatch => {
    try {
        await fetch.get('logout_user');
    } catch (err) {
        console.error(err);
    }
    dispatch({type: LOGOUT});
    localStorage.removeItem(USER_SESSION);
};
