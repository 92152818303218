import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ErrorBoundary from 'components/error-boundary';
import {Spin} from 'antd';
import {withTranslation} from 'react-i18next';

const withAccessActions = (WrappedComponent) => {
    class Wrapper extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
        };
        static defaultProps = {
            actions: {},
            isLoading: false,
        };

        state = {
            isLoading: true,
        };

        componentDidMount = () => {
            this.setState({isLoading: false});
        };

        componentDidUpdate = prevProps => {
            if (prevProps.isLoading && !this.props.isLoading) {
                this.setState({isLoading: false});
            }
        };

        shouldComponentUpdate = (nextProps, nextState) => {
            return (this.props.isLoading && !nextProps.isLoading) || (this.state.isLoading && !nextState.isLoading);
        };

        checkAccessRight = actionName => {
            const {actions = []} = this.props;
            return Object.values(actions).includes(actionName);
        };

        render = () => {
            const {actions, isLoading, ...rest} = this.props;

            if (this.state.isLoading) {
                return <Spin/>;
            }

            return (
                <ErrorBoundary>
                    <WrappedComponent {...rest} checkAccessRight={this.checkAccessRight}/>
                </ErrorBoundary>
            );
        }
    }

    return withTranslation()(connect(
        state => state.accessPermission,
    )(Wrapper));
};

export default withAccessActions;
