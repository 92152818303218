import axios from 'axios';
import delayAdapterEnhancer from 'axios-delay';
import {SERVICE_URL, USER_SESSION} from 'constants/index';

export const fetch = axios.create({
    baseURL: SERVICE_URL,
    adapter: delayAdapterEnhancer(axios.defaults.adapter)
});

fetch.defaults.headers.common['Accept-Language'] = 'ru';

fetch.interceptors.request.use(function (config) {
    const user_session = localStorage.getItem(USER_SESSION);

    if (user_session) {
        try {
            config.headers['X-Auth-Token'] = JSON.parse(user_session);
        } catch(err) {
            config.headers['X-Auth-Token'] = ''
        }
    } else {
        config.headers['X-Auth-Token'] = '';
    }
    config.delay = 300;

    return config;
}, function (error) {
    return Promise.reject(error);
});

fetch.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
});
