import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'antd';

export default class ErrorBoundary extends Component {
    static propTypes = {
        fallback: PropTypes.node,
        children: PropTypes.any.isRequired,
    };
    static defaultProps = {
        fallback: 'Something went wrong',
    };

    state = {
        hasError: false,
    };

    componentDidCatch() {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return (
                <Alert type="error" message={this.props.fallback} />
            );
        }

        return this.props.children;
    }
}
