import {
    GET_ACTIONS_PERMISSIONS_FAILURE,
    GET_ACTIONS_PERMISSIONS_SUCCESS,
    GET_ACTIONS_PERMISSIONS_REQUEST
} from 'constants/index';

const initialState = {
    actions: {},
    isLoading: false,
};

const accessPermission = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIONS_PERMISSIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ACTIONS_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                actions: {
                    ...state.actions,
                    ...action.payload,
                }
            };
        case GET_ACTIONS_PERMISSIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default accessPermission;
