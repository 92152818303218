import {fetch} from 'helpers';
import {
    USER_SESSION,
    GET_ACTIONS_PERMISSIONS_REQUEST,
    GET_ACTIONS_PERMISSIONS_SUCCESS,
    GET_ACTIONS_PERMISSIONS_FAILURE
} from 'constants/index';

export const describePermissions = () => async dispatch => {
    dispatch({type: GET_ACTIONS_PERMISSIONS_REQUEST});

    try {
        const token = JSON.parse(localStorage.getItem(USER_SESSION));
        const payload = await fetch.post(`describe_permissions`, {token});

        dispatch({
            type: GET_ACTIONS_PERMISSIONS_SUCCESS,
            payload,
        });
    } catch (e) {
        dispatch({type: GET_ACTIONS_PERMISSIONS_FAILURE});
    }
};
