import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {
    BankOutlined,
    CarOutlined,
    ControlOutlined, HomeOutlined,
    ProfileOutlined,
    UserOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import withAccessActions from 'components/page-wrapper';
import {
    SYS_VIEW_ACCESS_PROFILES,
    SYS_VIEW_PERSONS,
    SYS_VIEW_ENTITIES,
    SYS_VIEW_CONTROLLERS,
    SYS_VIEW_CARS,
    SYS_VIEW_LOGS
} from 'constants/actions';

const SidebarMenu = (props) => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {checkAccessRight} = props;
    return (
        <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
            <Menu.Item key="/" icon={<HomeOutlined/>}>
                <Link to="/">{t('Home')}</Link>
            </Menu.Item>
            {checkAccessRight(SYS_VIEW_ENTITIES) &&
            <Menu.Item key="/entities" icon={<BankOutlined/>}>
                <Link to="/entities">{t('Entities')}</Link>
            </Menu.Item>
            }
            {checkAccessRight(SYS_VIEW_PERSONS) &&
            <Menu.Item key="/persons" icon={<UserOutlined/>}>
                <Link to="/persons">{t('Persons')}</Link>
            </Menu.Item>
            }
            {checkAccessRight(SYS_VIEW_CARS) &&
            <Menu.Item key="/cars" icon={<CarOutlined/>}>
                <Link to="/cars">{t('Cars')}</Link>
            </Menu.Item>
            }
            {checkAccessRight(SYS_VIEW_CONTROLLERS) &&
            <Menu.Item key="/controlled" icon={<ControlOutlined/>}>
                <Link to="/controlled">{t('Controlled')}</Link>
            </Menu.Item>
            }
            {checkAccessRight(SYS_VIEW_LOGS) &&
            <Menu.Item key="/logs" icon={<ProfileOutlined/>}>
                <Link to="/logs">{t('Logs')}</Link>
            </Menu.Item>
            }
            {checkAccessRight(SYS_VIEW_ACCESS_PROFILES) &&
            <Menu.Item key="/access-profiles" icon={<SolutionOutlined/>}>
                <Link to="/access-profiles">{t('Access Profiles')}</Link>
            </Menu.Item>
            }
        </Menu>
    );
};

export default withAccessActions(
    SidebarMenu,
    [SYS_VIEW_ACCESS_PROFILES, SYS_VIEW_PERSONS, SYS_VIEW_ENTITIES,
            SYS_VIEW_CONTROLLERS, SYS_VIEW_CARS, SYS_VIEW_LOGS]
);
