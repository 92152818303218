import React from 'react';
import {useTranslation} from 'react-i18next';
import {Result} from 'antd';

const NotFound = () => {
    const {t} = useTranslation();

    return (
        <Result
            title="404"
            status="404"
            subTitle={t('Sorry, the page you visited does not exist.')}
        />
    );
};

export default NotFound;