import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './store';
import Main from 'components/layouts/main';
import './helpers/i18n';
import 'antd/dist/antd.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <Main/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
