// export const SERVICE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081';
export const SERVICE_URL = '/api';
export const USER_SESSION = 'USER_SESSION';
export const LANG = 'LANG';
export const ENTITY_STATUS = ['ACTIVE', 'ACCESS_RESTRICTION', 'RESTRICT_RADIO', 'RESTRICT_PHONE'];
export const PERSON_ROLES = ['OWNER', 'FAMILY_MEMBER', 'OTHER_MEMBER', 'FRIEND', 'TAXI', 'SUPPLIER', 'SERVICEMAN', 'WORKER', 'GUEST', 'OTHER'];
export const ACTION_TYPE = ['OPEN', 'CLOSE', 'TOGGLE'];

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const CHECK_SESSION_TOKEN_FAILURE = 'CHECK_SESSION_TOKEN_FAILURE';
export const CHECK_SESSION_TOKEN_REQUEST = 'CHECK_SESSION_TOKEN_REQUEST';
export const CHECK_SESSION_TOKEN_SUCCESS = 'CHECK_SESSION_TOKEN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SWITCH_LANG = 'SWITCH_LANG';

export const GET_ACTIONS_PERMISSIONS_REQUEST = 'GET_ACTIONS_PERMISSIONS_REQUEST';
export const GET_ACTIONS_PERMISSIONS_SUCCESS = 'GET_ACTIONS_PERMISSIONS_SUCCESS';
export const GET_ACTIONS_PERMISSIONS_FAILURE = 'GET_ACTIONS_PERMISSIONS_FAILURE';
