import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Layout, Input, Form, Row, Col, Card, Button, Alert} from 'antd';
import {authorize} from 'actions';

const Login = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const failed = useSelector(state => state.auth.failed);
    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 14,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 6,
            span: 14,
        },
    };

    const onFinish = (values) => {
        dispatch(authorize(values));
    };

    return (
        <Layout className="main-layout">
            <Layout.Content>
                <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
                    <Col span={8}>
                        <Card title={t("LoginFull")}>
                            {failed && (
                                <Alert
                                    type="error"
                                    style={{marginBottom: 24}}
                                    message={t('Wrong credentials')}
                                    closable
                                />
                            )}
                            <Form
                                {...layout}
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                                initialValues={{remember: true}}
                            >
                                <Form.Item
                                    name="username"
                                    label={t('Username')}
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t('Password')}
                                    rules={[{required: true}]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        {t('Submit')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
};
export default memo(Login);
